// var stickyHeader = false;
var flyoutNav = true;
// var fullscreenNav = true;
// var hoverSensitiveNav = true;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; 

// Remove home page carousel click
if( $('body.subsite[class*="annual-report"]').length ) {
  $(".carouselSlideDetail").attr("data-url", null).css('cursor', 'auto');
  $(".carouselSlideTitle").attr("href", null).css('cursor', 'auto');
  $(".carouselSlideHeading a").attr("href", null).css('cursor', 'auto');
}

// Thing to make blockquote attributes easier
if (($('body[class*="PostBody"]').length = 1)) {
  var blockquotes = document.querySelectorAll("blockquote");
  for (var i = 0; i < blockquotes.length; i++) {
    if (blockquotes[i].innerHTML.includes("quote-attribute")) {
      blockquotes[i].classList.add("contains-attribution");
    }
  }
}